/* src/components/Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
}

.company-name {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.ai-highlight {
  color: #ff6347; /* Highlight the "AI" in orange/red */
  font-weight: bold;
}

.language-switcher {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.language-switcher button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.language-switcher button:hover {
  background-color: #e0e0e0;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 2rem;
}

nav ul li a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 1rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav-open ul {
    display: block;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your header height */
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }

  .menu-icon {
    font-size: 2rem;
    color: #000;
  }

  .header-left {
    flex-direction: row;
    align-items: center;
  }

  .language-switcher {
    display: flex;
  }
}

/* Desktop Styles */
@media (min-width: 769px) {
  .menu-toggle {
    display: none;
  }

  .language-switcher {
    display: flex;
  }
}

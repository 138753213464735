.ai-service {
    display: flex;
    align-items: center;
    padding: 5rem 2rem;
    background-color: #f5f5f7;
  }
  
  .ai-service-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  
  .ai-service-text {
    flex: 1;
    padding-right: 2rem;
    animation: slideInLeft 1s ease-in-out;
  }
  
  .ai-service-text h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .ai-service-text p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
  }
  
  .ai-service-image {
    flex: 1;
    animation: slideInRight 1s ease-in-out;
  }
  
  .ai-service-image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes slideInLeft {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
/* src/components/Footer.css */
footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

footer ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
}

footer ul li {
  display: inline;
  margin: 0 1rem;
}

footer ul li a {
  color: white;
  text-decoration: none;
}

footer p {
  margin: 0;
}

.testimonials {
    padding: 5rem 2rem;
    background-color: #f5f5f7;
    text-align: center;
  }
  
  .testimonials-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .testimonials-content h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .testimonials-slider {
    display: flex;
    overflow-x: scroll;
    gap: 2rem;
  }
  
  .testimonial {
    min-width: 300px;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    text-align: left;
  }
  
  .testimonial img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .testimonial p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .testimonial h4 {
    font-size: 0.9rem;
    color: #555;
  }
  
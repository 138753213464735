/* src/components/CostSaving.css */
.cost-saving {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-color: #f9f9f9;
  text-align: left;
  margin-bottom: 5rem;
  overflow: hidden;
}

.cost-saving-content {
  flex: 1;
  padding-right: 2rem;
}

.cost-saving-content h2 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cost-saving-content p {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.section-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .cost-saving {
    flex-direction: column; /* Stack text and image vertically on small screens */
    text-align: center; /* Center-align text */
  }

  .cost-saving-content {
    padding-right: 0; /* Remove padding on small screens */
    margin-bottom: 2rem; /* Add space between text and image */
  }

  .image-container {
    width: 100%;
  }
}

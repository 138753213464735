.appointment-order {
    position: relative;
    padding: 5rem 2rem;
    color: white;
  }
  
  .appointment-order-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    filter: brightness(50%);
  }
  
  .appointment-order-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .appointment-order-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .appointment-order-content p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
.scenarios {
    padding: 5rem 2rem;
    background-color: #f5f5f7;
  }
  
  .scenarios-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .scenarios-content h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .scenarios-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .scenario-card {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .scenario-card:hover {
    transform: translateY(-10px);
  }
  
  .scenario-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
    transition: filter 0.3s ease-in-out;
  }
  
  .scenario-card:hover img {
    filter: brightness(100%);
  }
  
  .scenario-card h3 {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: white;
    font-size: 1.5rem;
    margin: 0;
  }
  
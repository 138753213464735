/* src/components/Ending.css */
.ending {
  position: relative;
  padding: 0;
  text-align: center;
  color: white;
  margin-top: 5rem; /* Increase space above the section */
}

.image-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust this to make the section visually appealing */
  background-image: url('images/ending.jpg'); /* Path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ending-content {
  max-width: 600px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1); /* Slight background blur effect */
  border-radius: 8px;
}

.ending-content h2 {
  color: white;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.ending-content p {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.integration {
    padding: 5rem 2rem;
    background-color: #fff;
    text-align: center;
  }
  
  .integration-content {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .integration-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .integration-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #555;
    line-height: 1.6;
  }
  
  .integration-flow img {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
.banner {
    background: url('images/banner-bg.jpg') no-repeat center center/cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
  }
  
  .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }
  
  .banner-content {
    z-index: 2;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .banner-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    animation: fadeInUp 1.2s ease-in-out;
  }
  
  .banner-content p {
    font-size: 1.2rem;
    margin-top: 0.5rem;
    animation: fadeInUp 1.5s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  